import React, { useState, useRef } from "react";
import "./Accordian.Styles.css"; // Import CSS for styles
import accordOpen from "../../assets/accordOpen.png";
import accordClosed from "../../assets/accordClosed.png";

const Accordion = ({ title, key, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordian" key={key}>
      <div
        className={`${isOpen ? "accordionOpen" : "accordionHeader"}`}
        onClick={toggleAccordion}
      >
        <h4 className="accordTitle">{title}</h4>
        {isOpen ? (
          <img src={accordOpen} className="accordIconImg" />
        ) : (
          <img src={accordClosed} className="accordIconImg" />
        )}
      </div>
      <div
        ref={contentRef}
        className={`accordionContent ${isOpen ? "open" : "closed"}`}
        style={{ maxHeight: isOpen ? contentRef.current.scrollHeight : 0 }}
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;
