import React, { useEffect } from "react";
import TopRated from "../../CommonComponents/Top_rated/TopRated";
import "../../IT Staff Augmentation Services/Style.css";
import img2 from "../../assets/img/listpic3.png";
import img4 from "../../assets/img/listpic4.png";
import Listing from "../../CommonComponents/WebDevelopmentServicesListing/Listing";
import Hiring from "../../CommonComponents/Hiring/Hiring";
import blueArrow from "../../assets/img/blueArrow.svg";
import Feedback from "../../CommonComponents/Feedback";
import MoreCaseStudies from "../../CommonComponents/MoreCaseStudies";
import service4 from "../../assets/Service4_lwrbnr.png";
import servicebnr from "../../assets/service4_bnr.png";
import Projects from "../../CommonComponents/Projects";
import Service4_dev from "../../assets/service4-dev.png";
import Quote from "../../CommonComponents/Quote";
import Banner from "../../CommonComponents/ServiceBanner/Banner";
import Industries from "../../CommonComponents/Industries_served/Industries";
import DevelopementService from "../../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
import Details from "../../CommonComponents/ServiceDetails/details";

const ShopifyDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
      name: "eCommerce Development",
    },
    {
      name: "Open-Source Customization",
    },
    {
      name: "Mobile App Development",
    },
    {
      name: "MVP Development",
    },
    {
      name: "Migration/Upgradation",
    },
    {
      name: "Issues & Bug Fixes",
    },
  ];
  const DetailsContent = [
    {
      title: "1. Unparalleled support and maintenance:",
      content: `Your success is our priority, which is why we offer comprehensive support and maintenance services to keep your Shopify store running smoothly.`,
    },
    {
      title: "2. Seamless transition to mobile:",
      content:
        "In today's mobile-first world, having a responsive website is essential. Our experts specialize in creating mobile applications for Shopify websites.",
    },
    {
      title: "3. Shopify Development Requirements: ",
      content:
        "Trust the experts at CreationSoul to exceed your expectations. With our proven track record of success, unmatched expertise, and dedication to customer satisfaction, we are your trusted partner in e-commerce excellence.",
    },
  ];
  const TopFeatures = {
    feature1: " Unparalleled support and maintenance",
    feature2: "Seamless transition to mobile",
    feature3: "Shopify Development Requirements",
  };
  const ListOfDevelopement = [
    {
      name: "Open Source",
      img: img2,
      details: "Magento, Woocommerce, OpenCart",
    },
    {
      name: "Web Application",
      img: img4,
      details: "Angular, React, Vue.js, Node, Laravel,",
    },
    {
      name: "Hosted Platform",
      img: img4,
      details: "Shopify, BigCommerce",
    },
    {
      name: "Database",
      img: img2,
      details: "MySQL, MongoDB, PostgreSQL, Firebase",
    },
  ];

  return (
    <div>
      <Banner
        Heading="Shopify Development Services"
        SubHeading="Designed For Entrepreneurs"
        Pic={servicebnr}
      />
      <TopRated
        Heading="Your Ideal Custom Shopify Development Company"
        SubHeading={`At CreationSoul, we're more than just a Shopify development company .
 we're your dedicated partner in creating exceptional online experiences that bring unparalleled success to your eCommerce venture. With a commitment to excellence, innovation, and customer-centric solutions, we specialize in harnessing the power of Shopify to elevate your online store to new heights.
`}
        Pic={service4}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="Shopify Development Services for Different Platforms"
        Display="none"
        List={List}
      />
      <Details
        contentList={DetailsContent}
        TopFeatures={TopFeatures}
        SubHeading="With a team of experienced Shopify developers, we bring unmatched expertise to the table. From simple storefronts to complex eCommerce solutions, our developers have the skills and knowledge to make your vision a reality. With a team of experienced Shopify developers, we bring unmatched expertise to the table. From simple storefronts to complex eCommerce solutions, our developers have the skills and knowledge to turn your vision into reality. Our scalable Shopify solutions are designed to evolve with your business."
      />
      <DevelopementService
        className="s_listing1"
        List={ListOfDevelopement}
        Pic={Service4_dev}
        Heading="Shopify development at creationsoul"
        SubHeading="We made eCommerce website for many platforms"
      />
      <Industries />
      <Hiring />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Projects />
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default ShopifyDevelopment;
