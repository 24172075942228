import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const NodeDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. State-of-the-art technology for enhanced user experience:',
            content:'Leveraging the power of Node.js, we build state-of-the-art applications that take user experience to new heights.'
        }, 
        {
            title: '2. Seamless Integration:',
            content: 'Our expertise extends beyond development to include seamless integration across different software and technologies. You need to migrate from legacy systems.'
        },
        {
            title: '3. Unparalleled Support and Maintenance:',
            content: 'At CreationSoul, our commitment to excellence does not end with project delivery. We provide unmatched support and maintenance services to ensure your Node.js applications always run smoothly and efficiently.'
        }
    ]
    const TopFeatures = {feature1:' State-of-the-art technology for enhanced user experience',feature2:'Seamless Integration', feature3:'Unparalleled Support and Maintenance'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Node App Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Leading Node.js Development Experts' SubHeading='At our premier Node.js development company, we are where excellence meets innovation. As your trusted software partner, we specialize in building dynamic web applications using Node.js tailored to your unique traffic needs. With our dedicated team of Node.js engineers, we transform your ideas into advanced solutions that drive your business forward.' Pic={service3} />
            <Listing Heading='Business-Friendly' SubHeading='Node App Development Services for Different Platforms' Display='none' List={List} />
            <Details contentList={DetailsContent} TopFeatures={TopFeatures}  SubHeading={`With a team of experienced Node.js engineers, we bring years of expertise to the table, ensuring that your projects are not only developed but built with precision and efficiency. Our deep understanding of Node.js allows us to create solutions perfectly aligned with your business objectives We understand that every business has its own challenges and requirements. That's why we specialize in creating bespoke solutions that address your specific traffic needs. Whether you need a powerful web application, a real-time chatbot, or an IoT backend system, we have the expertise to bring your ideas to life.`} />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Node app development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="node_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default NodeDevelopment;
