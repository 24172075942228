import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const AngularDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Skills and Experience:',
            content:'With years of experience under our belts, our team of Angular developers boasts unmatched expertise in building bespoke solutions that drive business growth.'
        }, 
        {
            title: '2. Customized solutions:',
            content:'We understand that every business is unique, which is why we take a tailored approach to Angular development. From concept to deployment, we understand your requirements.'
        },
        {
            title: '3. Seamless Integration:',
            content: 'Our Angular development services are designed to integrate seamlessly with your existing systems and infrastructure.'
        }
    ]
    const TopFeatures = {feature1:' Skills and Experience',feature2:'Customized solutions', feature3:'Seamless Integration'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Angular App Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Your premier Angular development partner' SubHeading='At CreationSoul, we pride ourselves on being the leading custom Angular development company. With a dedicated team of skilled Angular app developers, we specialize in building cutting-edge Single-Page Applications (SPA) with fully functional, easily integrable, and modular frameworks.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='Angular App Development Services for Different Platforms' Display='none' List={List} />
            <Details  contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading='We understand the importance of functionality in todays digital landscape. That is why our Angular development services ensure that your applications are not only visually appealing but also fully functional, meeting the diverse needs of your users. With CreationSoul, you can rest assured that our Angular solutions are easy to integrate with existing systems, leading to seamless operation and enhancements. Allows productivity. We believe in providing sustainable solutions for the long term. Our Angular applications are designed with ease of maintenance in mind, making it easy for you to manage and update your systems as needed.' />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Angular app development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="angular_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default AngularDevelopment;
