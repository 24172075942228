import React, { useEffect } from "react";
import TopRated from "../../CommonComponents/Top_rated/TopRated";
import "../../IT Staff Augmentation Services/Style.css";
import img2 from "../../assets/img/listpic3.png";
import img4 from "../../assets/img/listpic4.png";
import Listing from "../../CommonComponents/WebDevelopmentServicesListing/Listing";
import Hiring from "../../CommonComponents/Hiring/Hiring";
import blueArrow from "../../assets/img/blueArrow.svg";
import Feedback from "../../CommonComponents/Feedback";
import MoreCaseStudies from "../../CommonComponents/MoreCaseStudies";
import service4 from "../../assets/Service4_lwrbnr.png";
import servicebnr from "../../assets/service4_bnr.png";
import Projects from "../../CommonComponents/Projects";
import Service4_dev from "../../assets/service4-dev.png";
import Quote from "../../CommonComponents/Quote";
import Banner from "../../CommonComponents/ServiceBanner/Banner";
import Industries from "../../CommonComponents/Industries_served/Industries";
import DevelopementService from "../../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
import Details from "../../CommonComponents/ServiceDetails/details";

const WooCommerceDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
      name: "eCommerce Development",
    },
    {
      name: "Open-Source Customization",
    },
    {
      name: "Mobile App Development",
    },
    {
      name: "MVP Development",
    },
    {
      name: "Migration/Upgradation",
    },
    {
      name: "Issues & Bug Fixes",
    },
  ];
  const DetailsContent = [
    {
      title: "1. Security and Scalability:",
      content: `At CreationSoul, safety is paramount. We offer the highest security measures to protect your valuable data throughout the migration and development.`,
    },
    {
      title: "2. Continuous support and maintenance:",
      content:
        "Your journey with CreationSoul does not end with the launch of your e-commerce store. We proudly offer 24/7 support and maintenance services, ensuring your website is always high-performing and bug-free.",
    },
    {
      title: "3. Transparent and Hassle-Free Process: ",
      content:
        "At CreationSoul our approach is based on transparency. We believe in keeping you informed and involved at every stage of the development process, ensuring seamless communication and a hassle-free experience from start to finish.",
    },
  ];
  const TopFeatures = {
    feature1: " Security and Scalability",
    feature2: " Continuous support and maintenance",
    feature3: "Transparent and Hassle-Free Process",
  };
  const ListOfDevelopement = [
    {
      name: "Open Source",
      img: img2,
      details: "Magento, Woocommerce, OpenCart",
    },
    {
      name: "Web Application",
      img: img4,
      details: "Angular, React, Vue.js, Node, Laravel,",
    },
    {
      name: "Hosted Platform",
      img: img4,
      details: "Shopify, BigCommerce",
    },
    {
      name: "Database",
      img: img2,
      details: "MySQL, MongoDB, PostgreSQL, Firebase",
    },
  ];

  return (
    <div>
      <Banner
        Heading="WooCommerce Development Services"
        SubHeading="Designed For Entrepreneurs"
        Pic={servicebnr}
      />
      <TopRated
        Heading="Custom WooCommerce development company"
        SubHeading={`CreationSoul is a company where innovation meets expertise to create tailor-made solutions for your e-commerce aspirations. As the leading custom WooCommerce development company, we specialize in empowering businesses with powerful and fully functional online stores that redefine the digital shopping experience.`}
        Pic={service4}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="WooCommerce Development Services for Different Platforms"
        Display="none"
        List={List}
      />
      <Details
        contentList={DetailsContent}
        TopFeatures={TopFeatures}
        SubHeading="Our experienced WooCommerce engineers boast unmatched expertise in leveraging WooCommerce's full potential. From conceptualization to execution, we bring your e-commerce vision to life with attention to detail and a commitment to excellence. Whether you want to build a sales-driven e-commerce website, migrate from another platform or create custom plugins, our team ensures unmatched quality at every step of the journey. From PSD to WooCommerce theme to convert your website to a mobile application, we use agile methods to maximize speed, efficiency, and functionality."
      />
      <DevelopementService
        className="s_listing1"
        List={ListOfDevelopement}
        Pic={Service4_dev}
        Heading="WooCommerce development at creationsoul"
        SubHeading="We made eCommerce website for many platforms"
      />
      <Industries />
      <Hiring />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Projects />
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default WooCommerceDevelopment;
