import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const DotNetDevelopment = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Skilled .NET Developers:',
            content:'Our team of experienced .NET developers are proficient in using the latest technologies and frameworks to build sophisticated applications.'
        }, 
        {
            title: '2. Commitment to Quality and Reliability:',
            content:'At CreationSoul, quality is non-negotiable. We adhere to industry best practices and rigorous quality assurance processes to ensure that each deliverable meets the highest standards of excellence.'
        },
        {
            title: '3. Seamless Upgradation and Migration Services: ',
            content: 'We understand the importance of staying ahead in a rapidly evolving technology landscape. That is why we offer seamless upgradation and migration services to take your existing applications to the next level.'
        }
    ]
    const TopFeatures = {feature1:'Skilled .NET Developers',feature2:'Commitment to Quality and Reliability', feature3:'Seamless Upgradation and Migration Services'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Dot Net Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Custom .NET Development Company' SubHeading='At CreationSoul, we pride ourselves on being a top-rated custom .net development company, offering comprehensive services tailored to meet the unique needs of your business. With years of experience and a team of experienced .NET developers, we deliver secure, scalable, high-performance enterprise applications that drive optimal business benefits.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='Dot Net Development Services for Different Platforms' Display='none' List={List} />
            <Details  contentList={DetailsContent} TopFeatures={TopFeatures}  SubHeading="Our holistic approach ensures seamless development and deployment of powerful solutions aligned with your business objectives. Whether you need a custom CMS, eCommerce platform, MVP development, API services, or migration/upgradation solutions, CreationSoul has you covered. Our expertise spans across domains, enabling us to meet diverse business requirements with precision and efficiency. We understand that one size does not fit all. That's why we take the time to understand your unique needs and tailor our solutions accordingly Whether you are a startup, SME, or a large enterprise, we have the expertise to build bespoke applications that power your business growth." />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Dot Net development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="dot_net_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default DotNetDevelopment;
