import React, { useEffect} from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const WordpressDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Future-ready solutions:',
            content:'In a rapidly evolving digital landscape, we understand the importance of future-proofing your online presence.'
        }, 
        {
            title: '2. Innovative Mobile Solutions:',
            content:'In todays mobile-first world, we go beyond websites to offer innovative mobile app development solutions.'
        },
        {
            title: '3. Customer-centric approach:',
            content: 'Your satisfaction is our priority. We listen, understand, and collaborate closely with you to bring your ideas to life.'
        }
    ]
    const TopFeatures = {feature1:' Future-ready solutions',feature2:'Innovative Mobile Solutions', feature3:'Customer-centric approach'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Wordpress Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Custom Wordpress Development Company' SubHeading='At CreationSoul, we embody the essence of innovation and excellence in creating bespoke WordPress solutions tailored to meet your unique needs. As a top-rated custom WordPress development company, we pride ourselves on delivering highly optimized, SEO-friendly websites at a fast pace.' Pic={service3} />
            <Listing Heading='Business-Friendly' SubHeading='Wordpress Development Services for Different Platforms' Display='none' List={List} />
            <Details contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading="Our team of experienced WordPress engineers specializes in custom WordPress development, ensuring that every project is handled with precision and efficiency. We all have experience in API integration, plugin development, or website maintenance. From custom CRM solutions to e-commerce development and everything in between, we offer a wide range of services to enhance your online presence. Whether you need a pixel-perfect website from PSD/Figma design or seamless migrations and updates, we've got you covered. Whether upgrading your website, migrating from another platform, or providing ongoing support and maintenance. You can count on us to deliver with the utmost professionalism and efficiency."/>
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Wordpress development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="wordpress_dev" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default WordpressDevelopment;
