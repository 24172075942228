import React, { useEffect} from "react";
import TopRated from "../../CommonComponents/Top_rated/TopRated";
import "../../IT Staff Augmentation Services/Style.css";
import img1 from '../../assets/img/listpic1.png';
import img2 from '../../assets/img/listpic3.png';
import img3 from '../../assets/img/listpic5.png';
import img4 from '../../assets/img/listpic4.png';
import Listing from "../../CommonComponents/MobileDevelopmentServicesListing/Listing";
import Hiring from "../../CommonComponents/Hiring/Hiring";
import blueArrow from "../../assets/img/blueArrow.svg";
import Feedback from "../../CommonComponents/Feedback";
import MoreCaseStudies from "../../CommonComponents/MoreCaseStudies";
import mobile from "../../assets/mobile_dev.png";
import Projects from "../../CommonComponents/Projects";
import Service1 from "../../assets/img/Service1.png";
import toprated from "../../assets/TopratedImg2.png";
import Quote from "../../CommonComponents/Quote";
import Banner from "../../CommonComponents/ServiceBanner/Banner";
import Industries from "../../CommonComponents/Industries_served/Industries";
import DevelopementService from "../../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
const Native_Android_Development = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
        name: 'App Development',
        img: img1,
        details:'Leveraging the power of JavaScript and React, we engineer cutting-edge cross-platform native applications. We ensure rapid delivery of feature-rich mobile solutions, optimizing both time and cost for our clients.'
     },
    {
        name: 'API Integration',
        img: img2,
        details: 'Improve the functionality of your React Native apps with seamless API integration. Our tailored solutions ensure enhanced user experience by keeping your mobile applications intuitive and user-friendly.'
    },
    {
        name: 'Migration and Upgradation',
        img: img3,
        details: 'Specializing in seamless conversions and upgrades of existing mobile applications using the React Native framework. We guarantee a smooth upgrade process without compromising quality or risking data loss.'
    },
    {
        name: 'Proactive Maintenance Solutions',
        img: img1,
        details: 'Our maintenance strategies incorporate a proactive approach with regular code testing, functional and technical improvements, and diligent design updates. Ensure sustainable quality and performance of applications.'
    },
    {
        name: 'Problem Solving',
        img: img4,
        details: 'Our experienced experts ensure a smooth running application for your users, quickly identifying and resolving any issues or errors encountered during the development phase.'
    },
    {
        name: 'Store setup',
        img: img3,
        details: 'After successful user testing and meticulous bug fixing, we handle seamless deployment of your React Native app to the app store. Enables easy download and installation'
    }
];
  const ListOfDevelopement = [
    {
      name: "IOS App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
    {
      name: "Android -App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
  ];

  return (
    <div>
      <Banner
        Heading="Native Android App Development Services"
        SubHeading="Designed For Entrepreneurs"
        Pic={Service1}
      />
      <TopRated
        Heading="Pioneering company in customized Android app solutions"
        SubHeading="At CreationSoul, we specialize in creating bespoke Android apps, setting the benchmark as the best Android app development firm. Our strength lies in architecting fully functional native Android apps equipped with impressive UI-UX design. With a track record of success, we specialize in building scalable, feature-rich custom Android mobile applications. Ready to improve your digital presence? Enlist the skills of our experienced Android engineers today."
        Pic={toprated}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="Native Android App Development Services for Different Platforms"
        Display="none"
        List={List}

      />

      <DevelopementService
        className="s_listing"
        List={ListOfDevelopement}
        Pic={mobile}
        Heading="Mobile app development at creationsoul"
        SubHeading="We made mobile app for both android and IOS"
      />
      <Industries />
      <Hiring page="android_App" />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Projects />
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default Native_Android_Development;
