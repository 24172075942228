import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const PhpDevelopment = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Dedicated support and maintenance:',
            content:'Our commitment to excellence extends beyond the initial launch. With CreationSoul, you can count on dedicated support and maintenance services to keep your digital assets running smoothly, ensuring uninterrupted business operations.'
        }, 
        {
            title: '2. Enhanced Business Opportunities:',
            content:'By partnering with CreationSoul, you unlock a world of opportunities for your business. Our solutions are designed to enhance your online presence, drive engagement, and ultimately improve your bottom line.'
        },
        {
            title: '3. Sharpen your competitive edge:',
            content: 'In todays fast-paced digital landscape, staying ahead of the competition is crucial. With CreationSoul as your custom PHP development partner, you gain a competitive edge with innovative solutions that set you apart.'
        }
    ]
    const TopFeatures = {feature1:' Dedicated support and maintenance',feature2:'Enhanced Business Opportunities', feature3:'Sharpen your competitive edge'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Php Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Your premier custom PHP development partner' SubHeading='At CreationSoul, we are more than just a custom PHP development company; We are your dedicated partner in creating exceptional digital experiences. With a focus on innovation, creativity, and reliability, we specialize in providing tailored PHP solutions that enable businesses across industries to thrive in the digital landscape.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='Php Development Services for Different Platforms' Display='none' List={List} />
            <Details  contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading='Our team consists of experienced PHP developers who have a lot of experience and expertise in creating tailored solutions. With a deep understanding of PHP frameworks and technologies, we bring your vision to life with precision and finesse. We understand that every business is unique, and so are its needs. At CreationSoul, we take a personal approach to each project, ensuring that our solutions are tailored to align with your goals and objectives perfectly. At CreationSoul, we empower businesses to reach new heights of success with state-of-the-art PHP solutions. committed to' />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Php development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="php_dev" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default PhpDevelopment;
