import React, { Suspense } from "react";
import "./digitalStyles.css";
import bannerRight from "../assets/bannerRight.png";
import rectangleMask from "../assets/RectangleMask.png";
import MaskTop from "../assets/MaskGirl.png";
import waveForm from "../assets/waveDiv.png";
import StraightLine from "../assets/img/Rectangle 47.png";
import social from "../assets/social.png";
import email from "../assets/email.png";
import content from "../assets/content.png";
import blueArrow from "../assets/img/blueArrow.svg";
import report from "../assets/report.png";
import seo from "../assets/seo.png";
import google from "../assets/google.png";
import line from "../assets/line.png";
import digital from "../assets/digital_background.png";
import circle from "../assets/circle.png";
import whyUs from "../assets/whyUs.png";
import socialGirl from "../assets/socialgirl.png";
import Industries from "../CommonComponents/Industries_served/Industries";
import Hiring from "../CommonComponents/Hiring/Hiring";
import Feedback from "../CommonComponents/Feedback";
import MoreCaseStudies from "../CommonComponents/MoreCaseStudies";
import { Triangle } from "react-loader-spinner";
import Projects from "../CommonComponents/Projects";
import Quote from "../CommonComponents/Quote";
import { Link } from "react-router-dom";
import Accordion from "./Accordian/Accordian";
const DigitalMarketing = () => {
  const accordionDetails = [
    {
      Ques: `why digital marketing is important now a days?`,
      content:
        "Digital marketing is crucial today due to the prevalence of online channels where consumers spend much of their time.",
    },
    {
      Ques: `why digital marketing is important for small business?`,
      content: `For small businesses, digital marketing offers cost-effective strategies to reach a wider audience and compete with larger competitors.`,
    },
    {
      Ques: `how digital marketing is different from traditional mark?`,
      content: `Digital marketing differs from traditional marketing by leveraging digital platforms such as social media, search engines, and email to engage with audiences in real time and track performance metrics more accurately.`,
    },
  ];
  const serviceDetails = [
    {
      serviceName: "Search Engine Optimization",
      serviceImg: seo,
      serviceInfo: `Search Engine Optimization (SEO) is an important component of digital marketing, focusing on improving your website's visibility on search engines like Google. At CreationSoul, our SEO experts use advanced techniques and industry best practices to ensure that your website not only attracts more visitors but also converts them into loyal customers. With our tailored SEO strategies, your business can grow sustainably and stay ahead of the competition.`,
      styleClass: "serviceImg",
    },
    {
      serviceName: "Social Media Marketing",
      serviceImg: social,
      serviceInfo: `Social media marketing uses platforms like Facebook, Instagram, Twitter, LinkedIn and others to promote your brand, engage with your audience and drive traffic to your website. At CreationSoul, we create comprehensive social media strategies that align with your business objectives and resonate with your target audience. Our services include content creation, community management, social media advertising and analytics Using the power of social media, we help you create a strong online presence.`,
      styleClass: "serviceImg2",
    },
    {
      serviceName: "Content Marketing",
      serviceImg: content,
      serviceInfo: `Content marketing focuses on creating and distributing valuable, relevant and consistent content to attract and engage a clearly defined audience. This strategy is designed to build trust and authority, ultimately driving profitable customer activity. At CreationSoul, we specialize in creating high-quality content that aligns with your brand's voice and objectives. From blog posts and articles to videos, infographics and ebooks, our content marketing services are designed to educate, inform and entertain your audience.`,
      styleClass: "serviceImg3",
    },
    {
      serviceName: "Email Marketing",
      serviceImg: email,
      serviceInfo: `Email marketing is a powerful tool to nurture leads, retain customers and drive sales. This involves sending targeted and personalized emails to your customers to promote products, share news and provide valuable information. At CreationSoul, we design and execute email marketing campaigns that deliver measurable results. Our services include email list management, campaign strategy, template design, content creation and performance analysis.`,
      styleClass: "serviceImg4",
    },
    {
      serviceName: "Analytics and Reporting",
      serviceImg: report,
      serviceInfo: `Analytics and reporting are critical to understanding the performance of your digital marketing efforts and making data-driven decisions. At CreationSoul, we provide comprehensive analytics and reporting services to track and measure your campaign performance. Our team uses advanced tools and methods to collect and analyze data on key metrics such as traffic, engagement, conversions, and ROI. We present this data in clear, actionable reports that provide insight into what's working and what needs improvement.`,
      styleClass: "serviceImg5",
    },
    {
      serviceName: "Google My Business Optimization",
      serviceImg: google,
      serviceInfo: `Google My Business (GMB) optimization is essential for local businesses to improve their online presence and attract more customers. GMB is a free tool that allows businesses to manage their online information across Google Search and Maps At CreationSoul, we optimize your GMB listing so that it appears prominently in local search results.`,
      styleClass: "serviceImg6",
    },
  ];
  return (
    <>
      <div className="BannerContainer">
        <div className="leftBannerside">
          <h1>We boost</h1>
          <h2>growth for your</h2>
          <h2>startup business</h2>
          <p>
            CreationSoul is a company where we are committed to driving your
            business growth through tailored and ROI-driven digital marketing
            solutions. As a top-rated Google partner agency, we understand that
            every business has unique needs and goals.
          </p>
          <button className="bannerLeftButn">Contact us</button>
        </div>
        <div className="rightBannerside">
          <img src={bannerRight} className="bannerRightImg" />
        </div>
      </div>
      <div className="secondBannerContainer">
        <div className="secondBannerLeftSide">
          <h1>What is digital</h1>
          <strong>marketing?</strong>
          <p>
            Digital marketing includes a range of strategies and tactics used to
            promote products or services through online channels. Unlike
            traditional marketing methods, digital marketing uses the power of
            the Internet and digital technology to reach larger audiences,
            engage potential customers, and drive conversions. Key components
            include search engine optimization (SEO), pay-per-click (PPC)
            advertising, social media marketing, content marketing, email
            marketing, and more. At CreationSoul, we leverage these tools to
            create an integrated and effective digital marketing plan tailored
            for your business, ensuring maximum return on investment and
            sustainable growth.
          </p>
        </div>
        <div className="secondBannerRightSide">
          <div className="overlappingContainer">
            <img src={rectangleMask} className="secondBannerRightImg1" />
            <img src={MaskTop} className="secondBannerRightImg2" />
          </div>
        </div>
      </div>
      <div className="midContentContainer">
        <img src={waveForm} className="waveImg" />
        <div className="midContentHeader">
          <img src={StraightLine} alt="line" />
          <h2>Our Services</h2>
        </div>
        <div className="cardContainer">
          {serviceDetails.map((item, index) => (
            <div className="cardBox" key={index}>
              <img
                className={item.styleClass}
                src={item.serviceImg}
                alt="serviceImg"
              />
              <h2>{item.serviceName}</h2>
              <img className="lineImg" src={line} />
              <div className="infoContainer">
                <h3>{item.serviceInfo}</h3>
              </div>
              <button className="cardButn">Know more</button>
            </div>
          ))}
        </div>
      </div>
      <div className="lowerMidContainer">
        <img src={digital} alt="bg_img" />
        <div className="strategyContainer">
          <div className="strategyHeader">
            <h2>
              BEST <span>MARKETING</span>
              <br />
              COMPANY.
            </h2>
          </div>
          <div className="list">
            <img src={circle} />
            <h3>Targeted Reach</h3>
          </div>
          <div className="list">
            <img src={circle} />
            <h3>Measurable Results</h3>
          </div>
          <div className="list">
            <img src={circle} />
            <h3>Cost-Effectiveness</h3>
          </div>
        </div>
      </div>
      <div className="WhyUsContainer">
        <div className="WhyUsLeftContainer">
          <img src={whyUs} className="whyUsImg" />
        </div>
        <div className="WhyUsRightContainer">
          <h1>Why should </h1>
          <h1>You choose us?</h1>
          <h4>
            At CreationSoul, we are not just a digital marketing agency; We are
            your dedicated partner in driving business success. As a top-rated
            Google partner, our expertise and commitment set us apart. Our team
            of experienced professionals understands that every business is
            unique, requiring personalized strategies tailored to specific goals
            and needs. We pride ourselves on delivering ROI-driven solutions
            through a comprehensive suite of services including search engine
            optimization (SEO), pay-per-click (PPC) advertising, social media
            marketing, content marketing, and email marketing.
          </h4>
          <button className="bannerLeftButn">Contact us</button>
        </div>
      </div>
      <div className="last_Section">
        <img src={socialGirl} className="lastSectionImg" />
        <div className="accordionContainer">
          {accordionDetails.map((item, index) => (
            <Accordion title={item.Ques} content={item.content} key={index} />
          ))}
        </div>
      </div>
      <Industries />
      <Hiring />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Suspense
          fallback={
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#002EF4"
              ariaLabel="triangle-loading"
              wrapperStyle={{ margin: "auto", marginTop: "5rem" }}
              wrapperClass=""
            />
          }
        >
          <Projects />
        </Suspense>

        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </>
  );
};

export default DigitalMarketing;
