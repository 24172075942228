import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const LaravelDevelopment = () => {
    const [selectedService, setSelectedService] = useState(
        "Custom application development"
      );
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Innovative solutions for every business:',
            content:'We understand that every business is unique, so we take a customized approach to every project we undertake. Our team works closely with you to understand your needs, goals and challenges.'
        }, 
        {
            title: '2. Seamless Migration and Upgradation:',
            content:'If you want to migrate your existing website or application to Laravel, then CreationSoul is best for you.'
        },
        {
            title: '3. 24/7 Maintenance and Support:',
            content: 'At CreationSoul, we believe in providing unparalleled customer support to our clients. Our dedicated support team is available 24/7.'
        }
    ]
    const TopFeatures = {feature1:' Innovative solutions for every business',feature2:'Seamless Migration and Upgradation', feature3:'24/7 Maintenance and Support'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Laravel Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Your Premier Custom Laravel Development Company' SubHeading='At CreationSoul, we dont just build websites; We create digital experiences that resonate with your audience and enhance your brand presence. As a top-rated custom Laravel development company, we specialize in providing tailored solutions that perfectly align with your business objectives and exceed your expectations.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='Laravel Development Services for Different Platforms' Display='none' List={List} />
            <Details contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading="Our team of experienced Laravel developers has a wealth of experience and expertise in leveraging the power of Laravel to build robust, scalable, and aesthetically appealing web applications. From simple updates to complex projects, our developers are well-equipped to tackle any challenge with precision. At CreationSoul, we offer a wide range of Laravel development services tailored to meet your specific needs. Whether you're looking to build a custom CMS, an eCommerce application, an MVP, or an API, we have the expertise and resources to bring your vision to life." />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Laravel development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="laravel_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default LaravelDevelopment;
