import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const JavaDevelopment = () => {
    const [selectedService, setSelectedService] = useState(
        "Custom application development"
      );
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Quality Assurance and Support:',
            content: 'We prioritize quality assurance and provide comprehensive maintenance and support services. Our rigorous testing and bug-fixing processes ensure that your Java product is error-free and ready to move your business forward.'
        }, 
        {
            title: '2. Expert API Development:',
            content: 'Our Java experts specialize in building powerful APIs using the Java framework, enabling you to build cloud-friendly applications and take your business to new heights.'
        },
        {
            title: '3. Seamless Migration Services:',
            content: 'If you want to migrate your existing website or application to a more advanced Java-based version, CreationSoul is here to help. Our migration and upgradation services ensure a smooth transition without any loss of data.'
        }
    ]
    const TopFeatures = {feature1:' Quality Assurance and Support',feature2:'Expert API Development', feature3:'Seamless Migration Services'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Java Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='We are the leading Custom Java Development Company' SubHeading='CreationSoul is the ultimate destination for all your custom Java development needs. As a top-rated custom Java development company, we pride ourselves on providing exceptional solutions tailored to your unique requirements. Here is why you should choose CreationSole for your next project. Our skilled developers are always ready to serve you best.' Pic={service3} />
            <Listing setSelectedService={setSelectedService} Heading='Business-Friendly' SubHeading='Java Development Services for Different Platforms' Display='none' List={List} />
            <Details title={selectedService} contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading='At CreationSoul, we use the best tools and technologies available to develop innovative Java products. From advanced CMS platforms to state-of-the-art e-commerce solutions, we stay updated with the latest trends to deliver state-of-the-art applications. Our highly skilled Java developers specialize in creating native and cross-platform applications for various devices. Our expertise ensures that your project is in capable hands, resulting in robust and secure solutions. Whether we need a custom CMS, eCommerce platform, MVP development, API management, or migration and upgradation services, CreationSoul has you covered with utmost care. Our experienced team is always ready to provide solutions ranging from complex banking portals to large-scale enterprise applications.' />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Web app development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="react_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default JavaDevelopment;
