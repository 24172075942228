import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const DrupalDevelopment = () => {
    const [selectedService, setSelectedService] = useState(
        "Custom application development"
      );
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Seamless migration and upgrade:',
            content:'If you have an old website that needs a refresh. Our team can manage the migration and upgrade process seamlessly.'
        }, 
        {
            title: '2. Reliable maintenance and support:',
            content:'Our commitment to your success does not end once the project is launched. We provide ongoing support and maintenance services to keep your Drupal website running smoothly.'
        },
        {
            title: '3. Mobile Application Development:',
            content: 'Take your Drupal-powered website to the next level with our mobile application development services.'
        }
    ]
    const TopFeatures = {feature1:'Seamless migration and upgrade',feature2:'Reliable maintenance and support', feature3:' Mobile Application Development'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Drupal Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Your premier custom Drupal development partner' SubHeading='At CreationSoul, we pride ourselves on being a top-rated custom Drupal development company, dedicated to creating secure, engaging and scalable digital solutions for businesses of all sizes. With a team of highly skilled Drupal developers, we offer comprehensive services tailored to meet your specific needs and exceed your expectations.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='Drupal Development Services for Different Platforms' Display='none' List={List} />
            <Details contentList={DetailsContent} TopFeatures={TopFeatures} SubHeading="Our team provides bespoke solutions that are perfectly aligned with your business goals. Whether you need a simple website or a complex enterprise-level application, we have the expertise to bring your vision to life. From MVP development to enterprise-level maintenance and support, we offer complete Drupal development services to ensure success at every stage of your project's lifecycle. Our solutions are built to be stable, secure and feature-rich, ensuring a seamless shopping experience for your customers." />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Drupal development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries />
            <Hiring page="drupal_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default DrupalDevelopment;
