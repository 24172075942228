import React, { useEffect, useState } from "react";
import TopRated from "../../CommonComponents/Top_rated/TopRated";
import "../../IT Staff Augmentation Services/Style.css";
import img1 from '../../assets/img/listpic1.png';
import img2 from '../../assets/img/listpic3.png';
import img3 from '../../assets/img/listpic5.png';
import img4 from '../../assets/img/listpic4.png';
import Listing from "../../CommonComponents/MobileDevelopmentServicesListing/Listing";
import Hiring from "../../CommonComponents/Hiring/Hiring";
import blueArrow from "../../assets/img/blueArrow.svg";
import Feedback from "../../CommonComponents/Feedback";
import MoreCaseStudies from "../../CommonComponents/MoreCaseStudies";
import mobile from "../../assets/mobile_dev.png";
import Projects from "../../CommonComponents/Projects";
import Service1 from "../../assets/img/Service1.png";
import toprated from "../../assets/TopratedImg2.png";
import Quote from "../../CommonComponents/Quote";
import Banner from "../../CommonComponents/ServiceBanner/Banner";
import Industries from "../../CommonComponents/Industries_served/Industries";
import DevelopementService from "../../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
const Native_IOS_Development = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
        name: 'App Development',
        img: img1,
        details:'Leveraging the power of JavaScript and React, we engineer cutting-edge cross-platform native applications. We ensure rapid delivery of feature-rich mobile solutions, optimizing both time and cost for our clients.'
     },
    {
        name: 'API Integration',
        img: img2,
        details: 'Improve the functionality of your React Native apps with seamless API integration. Our tailored solutions ensure enhanced user experience by keeping your mobile applications intuitive and user-friendly.'
    },
    {
        name: 'Migration and Upgradation',
        img: img3,
        details: 'Specializing in seamless conversions and upgrades of existing mobile applications using the React Native framework. We guarantee a smooth upgrade process without compromising quality or risking data loss.'
    },
    {
        name: 'Proactive Maintenance Solutions',
        img: img1,
        details: 'Our maintenance strategies incorporate a proactive approach with regular code testing, functional and technical improvements, and diligent design updates. Ensure sustainable quality and performance of applications.'
    },
    {
        name: 'Problem Solving',
        img: img4,
        details: 'Our experienced experts ensure a smooth running application for your users, quickly identifying and resolving any issues or errors encountered during the development phase.'
    },
    {
        name: 'Store setup',
        img: img3,
        details: 'After successful user testing and meticulous bug fixing, we handle seamless deployment of your React Native app to the app store. Enables easy download and installation'
    }
];
  const ListOfDevelopement = [
    {
      name: "IOS App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
    {
      name: "Android -App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
  ];

  return (
    <div>
      <Banner
        Heading="Native iOS App Development Services"
        SubHeading="Designed For Entrepreneurs"
        Pic={Service1}
      />
      <TopRated
        Heading="Premier iOS App Development Services"
        SubHeading="Leveraging the power of agile methodology, our experienced team specializes in developing bespoke native iOS applications for various Apple devices. We not only understand your business vision but also translate it into a perfectly integrated iOS application, overcoming obstacles to ensure an unparalleled user journey. With a judicious selection of cutting-edge technologies, our iOS developers ensure high-level security, unwavering reliability, and excellent performance for your mobile apps."
        Pic={toprated}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="Native IOS App Development Services for Different Platforms"
        Display="none"
        List={List}
      />
      <DevelopementService
        className="s_listing"
        List={ListOfDevelopement}
        Pic={mobile}
        Heading="Mobile app development at creationsoul"
        SubHeading="We made mobile app for both android and IOS"
      />
      <Industries />
      <Hiring page="ios_App" />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Projects />
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default Native_IOS_Development;
