import React, { useEffect} from "react";
import TopRated from "../../CommonComponents/Top_rated/TopRated";
import "../../IT Staff Augmentation Services/Style.css";
import img1 from '../../assets/img/listpic1.png';
import img2 from '../../assets/img/listpic3.png';
import img3 from '../../assets/img/listpic5.png';
import img4 from '../../assets/img/listpic4.png';
import Listing from "../../CommonComponents/MobileDevelopmentServicesListing/Listing";
import Hiring from "../../CommonComponents/Hiring/Hiring";
import blueArrow from "../../assets/img/blueArrow.svg";
import Feedback from "../../CommonComponents/Feedback";
import MoreCaseStudies from "../../CommonComponents/MoreCaseStudies";
import mobile from "../../assets/mobile_dev.png";
import Projects from "../../CommonComponents/Projects";
import Service1 from "../../assets/img/Service1.png";
import toprated from "../../assets/TopratedImg2.png";
import Quote from "../../CommonComponents/Quote";
import Banner from "../../CommonComponents/ServiceBanner/Banner";
import Industries from "../../CommonComponents/Industries_served/Industries";
import DevelopementService from "../../CommonComponents/Developement_services/DevelopementService";
import { Link } from "react-router-dom";
const Flutter_Development = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const List = [
    {
        name: 'App Designing',
        img: img1,
        details:'Our talented UI/UX team creates the perfect design for your Android app, enhancing the user experience and driving your business forward.'
     },
    {
        name: 'Custom App Development',
        img: img2,
        details: 'Harnessing the power of Flutter, we create tailor-made mobile applications to meet your business needs. With a single codebase, we ensure seamless app development across all platforms.'
    },
    {
        name: 'Web to App Conversion',
        img: img3,
        details: 'Experience a unified user journey as we migrate your web application to a mobile platform, delivering an omnichannel experience to your audience.'
    },
    {
      name: 'Migration and Update',
      img: img1,
      details: 'Our expert Flutter developers facilitate the smooth migration of your existing applications across different platforms and operating systems. Stay ahead of the curve with timely updates and enhancements to your Android or iOS app.'
    },
    {
        name: 'Maintenance',
        img: img4,
        details: 'We take pride in providing top-notch maintenance services for your mobile applications. From functional improvements to bug tracking and design updates, we have got you covered.'
    },
    {
        name: 'Tests and bug fixes',
        img: img3,
        details: 'Our experienced Flutter development team meticulously identifies and resolves any issues or bugs, ensuring a flawless app experience for your end users.'
    }
];
  const ListOfDevelopement = [
    {
      name: "IOS App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
    {
      name: "Android -App Development",
      img: img2,
      details:
        "Front-end: Swift, Objective-C Back-end: Node, Laravel, Firebase, Python, Java, Dot Net, PHP, GraphQL",
    },
  ];

  return (
    <div>
      <Banner
        Heading="Flutter App Development Services."
        SubHeading="Designed For Entrepreneurs"
        Pic={Service1}
      />
      <TopRated
        Heading="Enhance your digital presence with our best Flutter app development company"
        SubHeading="At CreationSoul, we are dedicated to creating bespoke Flutter applications, which elevate your digital presence to new heights. We specialize in cross-platform native-like app development and are committed to delivering dynamic, feature-rich solutions tailored to your business needs. Our team of skilled Flutter app developers harness the power of the Dart programming language, combining Material Design and Cupertino widgets to create visually stunning user interfaces. Grow your digital presence today with CreationSoul, your premier flutter app development partner."
        Pic={toprated}
      />
      <Listing
        Heading="Business-Friendly"
        SubHeading="Flutter App Development Services for Different Platforms"
        Display="none"
        List={List}
      />
      <DevelopementService
        className="s_listing"
        List={ListOfDevelopement}
        Pic={mobile}
        Heading="Flutter app development at creationsoul"
        SubHeading="We made mobile app for both android and IOS"
      />
      <Industries />
      <Hiring page="flutter_App" />
      <Feedback />
      <MoreCaseStudies />
      <div className="cont_project">
        <Projects />
        <Link to="/case-study">
          <button className="all">
            View More <img src={blueArrow} alt="arrow" />
          </button>
        </Link>
      </div>
      <Quote />
    </div>
  );
};

export default Flutter_Development;
