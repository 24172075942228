import React, { useEffect, useState } from 'react';
import TopRated from '../../CommonComponents/Top_rated/TopRated';
import '../../IT Staff Augmentation Services/Style.css';
import img2 from '../../assets/img/listpic3.png';

import Listing from '../../CommonComponents/WebDevelopmentServicesListing/Listing'
import Hiring from '../../CommonComponents/Hiring/Hiring';
import blueArrow from '../../assets/img/blueArrow.svg';
import Feedback from '../../CommonComponents/Feedback';
import MoreCaseStudies from '../../CommonComponents/MoreCaseStudies';
import service3 from '../../assets/Service3_lwrbnr.png';
import servicebnr from '../../assets/service3_bannerTop.png';
import Projects from '../../CommonComponents/Projects';
import servDev from '../../assets/service3_dev.png';
import Quote from '../../CommonComponents/Quote';
import Banner from '../../CommonComponents/ServiceBanner/Banner';
import Industries from '../../CommonComponents/Industries_served/Industries';
import DevelopementService from '../../CommonComponents/Developement_services/DevelopementService';
import { Link } from 'react-router-dom';
import Details from '../../CommonComponents/ServiceDetails/details';

const PythonDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const List = [
        {
            name: 'Custom application development',
        },
        {
            name: 'E-commerce',
        },
        {
            name: 'Application Maintenance',
        },
        {
            name: 'API Development',
        },
        {
            name: 'CMS Development',
        },
        {
            name: 'Issues & Bug Fixes',
        }
    ];
    const DetailsContent = [
        {
            title: '1. Seamless integration and scalability:',
            content:'We understand the importance of seamless integration and scalability in todays rapidly evolving business environment. Our solutions are designed with scalability in mind.'
        }, 
        {
            title: '2. Dedicated support and maintenance:',
            content: 'Beyond development, CreationSoul offers comprehensive support and maintenance services to ensure the smooth operation of your applications.'
        },
        {
            title: '3. Proven track record of success:',
            content: 'With a proven track record of delivering successful projects across various industries, CreationSoul has earned a reputation for excellence and reliability.'
        }
    ]
    const TopFeatures = {feature1:' Seamless integration and scalability',feature2:'Dedicated support and maintenance', feature3:'Proven track record of success'}
    const ListOfDevelopement = [
        {
            name: 'Front-End Technologies',
            img: img2,
            details: 'Angular, React, Vue.js, Bootstrap'
        },
        {
            name: 'Backend-Technologies',
            img: img2,
            details: 'PHP, Laravel, Node, Python, Java, Dot Net, MySQL, MongoDB'
        }
    ];
    return (
        <div>
            <Banner Heading='Python Development Services' SubHeading='Designed For Entrepreneurs' Pic={servicebnr} />
            <TopRated Heading='Welcome to CreationSoul - Your Premier Custom Python Development Company' SubHeading='At CreationSoul, we are proud to be a top-rated custom Python development company, dedicated to building high-performance websites and applications tailored to your unique business needs. With an experienced team of Python developers, we specialize in providing innovative solutions that help your business thrive and future-proof in todays competitive marketplace.' Pic={service3} />
            <Listing  Heading='Business-Friendly' SubHeading='Python Development Services for Different Platforms' Display='none' List={List} />
            <Details  contentList={DetailsContent} TopFeatures={TopFeatures}   SubHeading='Our team of experienced Python developers has extensive expertise in using a wide range of Python tools and frameworks, including Pylon, Web2P, Turbogears, Pyramid, Flask, and Django. We use these technologies to build powerful, scalable, and feature-rich applications that exceed your expectations. Whether you need content management systems (CMS), eCommerce applications, MVP development, API management, or migration and upgradation services, CreationSoul has you covered. We specialize in developing tailor-made solutions perfectly aligned with your business goals and objectives. CreationSoul is committed to delivering the best quality and implementing cutting-edge ideas to ensure your website or mobile app stands out in the digital landscape. Our relentless pursuit of excellence drives us to constantly innovate and push the boundaries of whats possible with Python development.' />
            <DevelopementService className="s_listing" List={ListOfDevelopement} Pic={servDev} Heading='Python development at creationsoul' SubHeading='We made mobile app for both android and IOS' />
            <Industries/>
            <Hiring page="react_App" />
            <Feedback />
            <MoreCaseStudies />
            <div className="cont_project">
                <Projects />
                <Link to='/case-study'><button className='all'>View More <img src={blueArrow} alt='arrow' /></button></Link>
            </div>
            <Quote />
        </div>
    );
};

export default PythonDevelopment;
